import React from 'react'
import '../../components/Footer/Footer.css'

const Footer = () => {
  return (
    <footer className="footer">
       
        <a className='foot' href="https://ibtidaasoftware.com/"> Developed by Ibtidaa Software.</a>
    </footer>
  )
}

export default Footer